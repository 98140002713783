export const excelAcceptableMimeType = {
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.oasis.opendocument.spreadsheet': []
}

export const imageAcceptableMimeType = {
    'image/*': ['.jpeg', '.jpg', '.png']
}

export const pdfAcceptableMimeType = {
    'application/pdf': ['.pdf']
}

export const docAcceptableMimeType = {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/x-abiword': ['.abw'],
    'application/vnd.oasis.opendocument.text': []
}

export const csvAcceptableMimeType = {
    'text/csv': ['.csv']
}

export const pptAcceptableMimeType = {
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.oasis.opendocument.presentation': [],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': []
}

export const videoAcceptableMimeType = {
    'video/*': ['.mp4'],
    'video/mpeg': ['.mpeg'],
    'video/mov': ['.mov']
}

export const audioAcceptableMimeType = {
    'audio/mpeg': ['mp3'],
    'audio/aac': ['.aac'],
    'audio/midi': [],
    'audio/x-midi': [],
    'audio/ogg': []
}

export const archiveMimeType = {
    'application/x-freearc': ['.arc'],
    'application/gzip': ['.gz'],
    // 'application/zip': ['.zip'],
    'application/x-7z-compressed': ['.7z']
}

export const phpMimeType = {
    'application/x-httpd-php': []
}

export const htmlMimeType = {
    'text/html': [],
}

export const javascriptType = {
    'text/javascript': []
}

export const jsonMimeType = {
    'application/json': [],
    'application/ld+json': []
}

export const executableMimeType = {
    'application/octet-stream': [],
    'application/x-msdownload': [],
    'application/exe': [],
    'application/x-exe': [],
    'application/dos-exe': [],
    'vms/exe': [],
    'application/x-winexe': [],
    'application/msdos-windows': [],
    'application/x-msdos-program': [],
    'application/java-archive': [],
    'application/vnd.apple.installer+xml': []
}

export const executableMimeTypeSet = [
    'application/octet-stream',
    'application/x-msdownload',
    'application/exe',
    'application/x-exe',
    'application/dos-exe',
    'vms/exe',
    'application/x-winexe',
    'application/msdos-windows',
    'application/x-msdos-program',
    'application/java-archive',
    'application/vnd.apple.installer+xml',
]

export const executableExtensionSet = [
    'dmg',
    'war',
    'app',
    'exe',
    'sh',
    'cmd',
    'bat',
    'rat',
    'bin',
    'inf',
    'com',
    'ipa',
    'osx',
    'pif',
    'run',
    'wsh',
    'apk',
]

export const collectionFileExtensionSet = [
    'photoslibrary',
    'musiclibrary'
]

export const restrictTypeCode = {
    collection: 'collection',
    executable: 'exe'
}