import { Component } from "react";
import ReactDOM from 'react-dom';

export default class ErrorMsg extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        document.getElementById('err-msg-root').appendChild(this.el);
    }

    componentWillUnmount() {
        document.getElementById('err-msg-root').removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.el);
    }

}